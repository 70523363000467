import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@kuna-pay/ui/ui/button';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import { MerchantAvatar } from '@kuna-pay/core/entities/merchant';

import {
  SelectPaymentMethod,
  SelectPaymentMethodButton,
} from '@kuna-pay/accept-payment/entities/payment-method';

import { DonationPageModel } from '../../page.model';
import { DonationProgressTracker } from '../donation-progress-tracker';
import styles from './choose-payment-method.module.scss';

type ChoosePaymentMethodProps = PropsWithChildren & {
  className?: string;
};

const ChoosePaymentMethod = memo(
  ({ children, className }: ChoosePaymentMethodProps) => {
    const $$model = DonationPageModel.useModel();
    const { t } = useTranslation();

    const data = useUnit($$model.$$donationQuery.$data)!;

    return (
      <SelectPaymentMethod
        className={className}
        $$model={$$model.$$selectPaymentMethod}
        prepend={<DonationProgressTracker />}
      >
        <DonationProgressTracker />

        <Paper className={clsx(styles.root, className)}>
          <MerchantAvatar
            className={styles.avatar}
            src={data?.Company?.avatar}
          />

          <Typography className={styles.title} variant='subtitle3'>
            {data?.Company?.title}
          </Typography>

          <Typography className={styles.subtitle} variant='h7'>
            {t('pages.donation.choose-payment-method.description')}
          </Typography>

          <SelectPaymentMethodButton $$model={$$model.$$selectPaymentMethod} />

          <DonationDetails />

          <SubmitButton />

          {children}
        </Paper>
      </SelectPaymentMethod>
    );
  }
);

const DonationDetails = memo(() => {
  const $$model = DonationPageModel.useModel();

  const { t } = useTranslation();

  const AssetFormat = useAssetFormat();
  const donation = useUnit($$model.$$donationQuery.$data)!;
  const [selectedPaymentMethod] = useUnit([
    $$model.$$selectPaymentMethod.$value,
  ]);
  const [isLoading, isRefetchingRatesPending, isError, rate] = useUnit([
    $$model.$$rates.$isLoading,
    $$model.$$rates.$isFetching,
    $$model.$$rates.$isError,
    $$model.$rate,
  ]);

  if (!selectedPaymentMethod) {
    return null;
  }

  if (isLoading || isError || !rate) {
    return (
      <Skeleton
        containerClassName={styles.detailsRoot}
        height={76}
        fullWidth
        borderRadius={4}
      />
    );
  }

  const formattedOneEquivalentAssetAmount = AssetFormat.formatAmount(
    1,
    donation.EquivalentAsset
  );
  const formattedPaymentMethodAssetExchangeRateAmount =
    AssetFormat.formatAmount(rate.toCurrency, selectedPaymentMethod.Asset);

  const exchangeRateStr = `${formattedOneEquivalentAssetAmount} ≈ ${formattedPaymentMethodAssetExchangeRateAmount}`;

  return (
    <div className={clsx(styles.details, styles.detailsRoot)}>
      {selectedPaymentMethod.Asset?.code !== donation.EquivalentAsset.code && (
        <>
          <div className={styles.detailsRow}>
            <Typography variant='numbers1'>
              {t('pages.donation.choose-payment-method.exchange-rate')}:
            </Typography>

            <Typography
              className={clsx({
                'text-black400': isRefetchingRatesPending,
              })}
              variant='numbers1'
              title={exchangeRateStr}
            >
              {exchangeRateStr}
            </Typography>
          </div>

          <Divider />
        </>
      )}

      <div className={styles.detailsRow}>
        <Typography variant='body3'>
          {t('pages.donation.choose-payment-method.fee')}:
        </Typography>

        <Typography
          className={clsx({
            'text-black400': isRefetchingRatesPending,
          })}
          variant='subtitle5'
          title={AssetFormat.formatAmount(
            donation.PaymentDetails?.fee ?? 0,
            donation.EquivalentAsset
          )}
        >
          {AssetFormat.formatAmount(
            donation.PaymentDetails?.fee ?? 0,
            donation.EquivalentAsset
          )}
        </Typography>
      </div>
    </div>
  );
});

const SubmitButton = memo(() => {
  const $$model = DonationPageModel.useModel();

  const { t } = useTranslation();

  const [selectedPaymentMethod, isSubmitting, onSubmit] = useUnit([
    $$model.$$selectPaymentMethod.$value,
    $$model.$isSubmitting,
    $$model.onSubmit,
  ]);

  return (
    <div className={styles.submit}>
      <Button
        variant='contained'
        color='primary'
        size='xl'
        onClick={onSubmit}
        loading={isSubmitting}
        fullWidth
      >
        {selectedPaymentMethod
          ? t('pages.donation.choose-payment-method.submit.selected', {
              replace: { asset: selectedPaymentMethod.asset },
            })
          : t('pages.donation.choose-payment-method.submit.initial')}
      </Button>
    </div>
  );
});

export { ChoosePaymentMethod };

import clsx from 'clsx';
import Decimal from 'decimal.js-light';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@kuna-pay/ui/ui/divider';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AssetParser } from '@kuna-pay/core/entities/asset';

import {
  PayWithKrakenButton,
  PayWithKunaButton,
  PayWithTRUSTWalletButton,
} from '@kuna-pay/accept-payment/features/pay-with';
import {
  $$payWithKrakenFeatureFlag,
  $$payWithKunaFeatureFlag,
  $$payWithTrustWalletFeatureFlag,
} from '@kuna-pay/accept-payment/shared/feature-flags/features';

import { CheckoutPageModel } from '../../page.model';
import {
  CheckoutProgressTracker,
  InvoiceAmounts,
  InvoiceTabs,
} from '../shared';
import { InvoiceAddress } from './invoice-address';
import styles from './procced-payment.module.scss';

type ProceedPaymentViewProps = PropsWithChildren & {
  className?: string;
};

const ProceedPaymentView = memo(
  ({ className, children }: ProceedPaymentViewProps) => (
    <>
      <CheckoutProgressTracker />

      <Paper className={clsx(styles.root, className)}>
        <InvoiceTabs>
          <div className={styles.container}>
            <InvoiceAddress className={styles.invoiceAddress} />

            <InvoiceAmounts.Root>
              <InvoiceAmounts.AmountLeft />

              <InvoiceAmounts.ReceivedAmount />
            </InvoiceAmounts.Root>

            <AlternativePaymentMethods />

            {children}
          </div>
        </InvoiceTabs>
      </Paper>
    </>
  )
);

const AlternativePaymentMethods = memo(() => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation();

  const invoice = useUnit($$model.$invoice)!;

  const payWithKunaFeatureFlag = useUnit($$payWithKunaFeatureFlag);
  const payWithKrakenFeatureFlag = useUnit($$payWithKrakenFeatureFlag);
  const payWithTrustWalletFeatureFlag = useUnit(
    $$payWithTrustWalletFeatureFlag
  );

  const amountLeft = AssetParser.toParsedWithPrecision(
    new Decimal(invoice.paymentAmount).sub(invoice.paidAmount),

    invoice.PaymentAsset!,

    { round: 'up' }
  );

  const trustWalletCoinId = invoice.PaymentMethod?.payload?.trustWalletCoinId;

  const canDisplayTrustWallet =
    payWithTrustWalletFeatureFlag.enabled && !!trustWalletCoinId;

  if (
    payWithKunaFeatureFlag.disabled &&
    payWithKrakenFeatureFlag.disabled &&
    canDisplayTrustWallet
  ) {
    return null;
  }

  return (
    <div className={styles.alternative}>
      <div className={styles.alternativeText}>
        <Divider />

        <Typography variant='body3'>
          {t('pages.checkout.proceed-payment.alternative')}
        </Typography>

        <Divider />
      </div>

      <div className={styles.methods}>
        {payWithKunaFeatureFlag.enabled && (
          <PayWithKunaButton
            code={invoice.PaymentMethod!.asset}
            method={invoice.PaymentMethod!.code}
            amount={amountLeft}
            address={invoice.Address!.address}
            memo={invoice.Address!.memo}
            expiresAt={invoice.expireAt}
          />
        )}

        {payWithKrakenFeatureFlag.enabled && (
          <PayWithKrakenButton code={invoice.PaymentMethod!.asset} />
        )}

        {canDisplayTrustWallet && (
          <PayWithTRUSTWalletButton
            asset={trustWalletCoinId}
            address={invoice.Address!.address}
            memo={invoice.Address!.memo}
            amount={amountLeft}
          />
        )}
      </div>
    </div>
  );
});

export { ProceedPaymentView };
